interface GTMData { 
  [key:string]: string
}
declare const dataLayer: GTMData[];

class GTMServiceClass{ 
  /**
   * example GTMService.pushCustomEvent({'event':'deleteReport', 'reportType':'CSLP'})
   * @param data 
   */
  pushCustomEvent(data: GTMData){ 
    dataLayer.push(data);
  }
}
export const GTMService = new GTMServiceClass();