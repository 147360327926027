import { i18n } from "@/main";

export default [
  {
    key: "missingChords",
    tagSpecific: false,
    mode: "error",
    triggeredMessage: i18n.t("R._MES_CSE_Rule_missingChords")
  },
  {
    key: "required",
    tagSpecific: true,
    mode: "error",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_Required")
  },
  {
    key: "extraTextIsValidTempo",
    tagSpecific: true,
    mode: "error",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_ExtraTextIsValidTempo")
  },
  {
    key: "extraTextIsValidKey",
    tagSpecific: true,
    mode: "error",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_ExtraTextIsValidKey")
  },
  {
    key: "appearsAfterTag",
    tagSpecific: true,
    mode: "error",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_AppearsAfterTag")
  },
  {
    key: "mustBeInMetaData",
    tagSpecific: true,
    mode: "off",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_MustBeInMetaData")
  },
  {
    key: "inputRequired",
    tagSpecific: true,
    mode: "error",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_InputRequired")
  },
  {
    key: "noInputRequired",
    tagSpecific: true,
    mode: "error",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_NoInputRequired")
  },
  {
    key: "overwrittenDuringEditing",
    tagSpecific: true,
    mode: "warning",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_OverwrittenDuringEditing")
  },
  {
    key: "singleUseOnly",
    tagSpecific: true,
    mode: "error",
    triggeredMessage: i18n.t("R.MES_CSE_Rule_SingleUseOnly")
  },
  {
    key: "removedOnExport",
    tagSpecific: true,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_RemovedOnExport"),
    mode: "info"
  },
  {
    key: "noSpaceInTagName",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_NoSpaceInTagName"),
    mode: "error"
  },
  {
    key: "existsOnOwnLine",
    tagSpecific: true,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_ExistsOnOwnLine"),
    mode: "error"
  },
  {
    key: "unsupportedTagByCCLI",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_UnsupportedTagByCCLI"),
    mode: "warning"
  },
  {
    key: "restrictedWords",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_RestrictedWords"),
    mode: "error"
  },
  {
    key: "invalidChords",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_UnrecognizedChords"),
    mode: "error"
  },
  {
    key: "chordCannotBeMultilined",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_ChordCannotBeMultilined"),
    mode: "error"
  },
  {
    key: "tagCannotBeMultilined",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_TagCannotBeMultilined"),
    mode: "error"
  },
  {
    key: "noWhiteSpaceInTagName",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_NoWhiteSpaceInTagName"),
    mode: "error"
  },
  {
    key: "missingBracketOnTag",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_MissingBracketOnTag"),
    mode: "error"
  },
  {
    key: "missingBracketOnChord",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_MissingBracketOnChord"),
    mode: "error"
  },
  {
    key: "lowercaseTags",
    tagSpecific: false,
    triggeredMessage: i18n.t("R.MES_CSE_Rule_LowercaseTags"),
    mode: "warning"
  }
];
