import { Component, Vue } from 'vue-property-decorator'
import download from 'downloadjs'
import { reverse, sortBy } from 'lodash-es'
import AvailableChords from '@/assets/json/Chords.json'
import AvailableDirectives from '@/assets/json/Directives'
import Rules from '@/assets/json/ChordProRules';

import { EditorState } from '@codemirror/state'
import { EditorView, highlightActiveLine, highlightActiveLineGutter, keymap } from '@codemirror/view'
import {
  historyKeymap,
  history,
  defaultKeymap,
  undo as codemirrorUndo,
  redo as codemirrorRedo,
  redoDepth,
  undoDepth
} from '@codemirror/commands'

import { StreamLanguage, syntaxHighlighting } from '@codemirror/language'
import { addRestrictedWords, ChordProLang, ChordProLint, highlights, addInvalidChords } from './ChordProLanguage'
import { diagnosticCount, forEachDiagnostic, linter, lintGutter } from '@codemirror/lint'
import { GTMService } from './GTMService'

export enum MobileMode {
  Editor = 0,
  Preview = 1,
  Lyrics = 2
}

@Component
class ChordProServiceClass extends Vue {
  availableChords = AvailableChords
  get availableDirectives() {
    return AvailableDirectives.filter(d => !d.rules.overwrittenDuringEditing);
  }
  columns = '2'
  mobileMode: MobileMode = MobileMode.Editor

  setMobile (mode: MobileMode) {
    if (mode === MobileMode.Preview){
      GTMService.pushCustomEvent({ event: 'cseToolbarMobilePreview'})
    }else if (mode === MobileMode.Lyrics){
      GTMService.pushCustomEvent({ event: 'cseToolbarMobileLyrics'})
    }
    this.mobileMode = mode;
  }

  get commonChords () {
    const matched = this.data.match(/\[([^\]]+)\]/g)
    const counts: {chord: string; count: number}[] = []
    matched?.forEach(c => {
      const found = counts.find(count => count.chord === c)
      if (found) {
        found.count++
      } else {
        counts.push({ chord: c, count: 1 })
      }
    })
    const sortedCounts = reverse(sortBy(counts, c => c.count))
    return sortedCounts.splice(0, 9).map(c => c.chord.replace(/(\[|\])/g, ''))
  }

  focus () {
    this.editorView?.focus()
  }

  undo () {
    if (!this.editorView) {
      return
    }
    GTMService.pushCustomEvent({event: 'cseToolbarUndo'});
    codemirrorUndo(this.editorView)
  }

  redo () {
    if (!this.editorView) {
      return
    }
    GTMService.pushCustomEvent({event: 'cseToolbarRedo'});
    codemirrorRedo(this.editorView)
  }

  insert (text: string) {
    if (!this.editorView) {
      return
    }
    this.focus()
    const range = this.editorView.state.selection.ranges[0]
    this.editorView.dispatch({
      changes: {
        from: range.from,
        to: range.to,
        insert: text
      },
      selection: { anchor: range.from + text.length }
    })
  }

  editorView: EditorView | null = null
  element: HTMLElement | null = null
  theme = EditorView.theme({
    '.cm-editor': { height: '100%' }
  })

  init (element: HTMLElement) {
    this.element = element

    this.editorView = new EditorView({
      state: this.createNewState(''),
      parent: document.querySelector('#chordpro-textarea') as HTMLElement
    })
  }

  createNewState (data: string) {
    return EditorState.create({
      doc: data,
      extensions: [

        keymap.of([...defaultKeymap, ...historyKeymap]),
        history(),
        this.theme,
        EditorView.updateListener.of((e) => {
          this.data = e.state.doc.toString()
        }),
        StreamLanguage.define(ChordProLang),
        syntaxHighlighting(highlights),
        linter(ChordProLint),
        lintGutter(),
        highlightActiveLine(),
        highlightActiveLineGutter()
      ]
    })
  }

  updateWithInvalidChords(chords: string[]){
    addInvalidChords(chords);
    this.editorView?.setState(this.createNewState(this.data))
  }
  updateWithRestrictedWords (words: string[]) {
    addRestrictedWords(words)
    this.editorView?.setState(this.createNewState(this.data))
  }

  updateData (data: string) {
    this.editorView?.setState(this.createNewState(data))
  }

  get restrictedWordsError(){
    if (!this.editorView) {
      return
    }
    let foundBadWords = false;
    forEachDiagnostic(this.editorView.state, (diagnostic) => {
      foundBadWords = foundBadWords || diagnostic.source === 'restrictedWords'
    });
    return foundBadWords
  }

  get errorCounts () {
    if (!this.editorView) {
      return
    }
    let errorCount = 0
    forEachDiagnostic(this.editorView.state, (diagnostic) => {
      if (diagnostic.severity === 'error') {
        errorCount++
      }
    })
    return errorCount
  }

  get missingDirectives() {
    const rule = Rules.find(r => r.key === 'required');
    const missingDirectives = [] as string[];

    if (rule && rule.mode === 'error'){
      const requiredDirectives = AvailableDirectives.filter(d => d.rules.required);
      requiredDirectives.forEach(d => {
        if (!this.data.match(new RegExp(`{${d.tag}\\s*:\\s*[^}]*}`, 'gi'))){
          missingDirectives.push(d.tag)
        }
      })

    }

    return missingDirectives;
  }

  get missingChords() {
    const rule = Rules.find(r => r.key === 'missingChords');
    if (rule && rule.mode === 'error') {
      return !this.data.match(new RegExp("\\[.+\\]",'gi'));
    }
    return false;
  }

  get disableUndo () {
    if (!this.editorView) {
      return true
    }
    return undoDepth(this.editorView.state) === 0
  }

  get disableRedo () {
    if (!this.editorView) {
      return true
    }
    return redoDepth(this.editorView.state) === 0
  }

  data = ''
  historyChange = true

  downloadAsFile (songName: string) {
    // const matched = this.data.match(/\{title\s*:\s*(?<name>.*)\}/)
    // let songName = matched?.groups?.name.trim() ?? 'Untitled'
    // songName = songName.replace(/[^a-z0-9]/gi, '_').toLowerCase()
    GTMService.pushCustomEvent({
      event: 'cseToolbarDownloadAsFile'
    })
    download(this.data, `${songName}.txt`, 'text/plain')
  }

  removeDirectivesFromChords(chords: string, title?: string, authors?: string){
    let updatedChords = chords;
    const removeDirectives = AvailableDirectives.filter(d => d.rules.overwrittenDuringEditing);

    removeDirectives.forEach(d => {
      updatedChords = updatedChords.replaceAll(new RegExp(`{\\s*\\b${d.tag}\\b\\s*:?\\s*([^}{]*)}`, 'gi'), '');
    })

    if (authors){
      updatedChords = `{author: ${authors}}\n` +updatedChords;
    }

    if (title){
      updatedChords = `{title: ${title}}\n` + updatedChords;
    }
    return updatedChords
  }
  showLyrics = true;
}

export const ChordProService = new ChordProServiceClass()
