import Directives from '@/assets/json/Directives'
import { HighlightStyle, StreamParser } from '@codemirror/language'
import { Diagnostic } from '@codemirror/lint'
import { Tag, tags } from '@lezer/highlight'
import ChordProRules from '@/assets/json/ChordProRules'
import { EditorView } from 'codemirror'
import Chords from '@/assets/json/Chords.json'

interface ChordProRule {
  key: string,
  tagSpecific: boolean,
  mode: 'error'|'warning'|'info',
  triggeredMessage: string
}

const filteredChordProRules = ChordProRules.filter(c => c.mode !== 'off') as ChordProRule[]
const tagMatchNoCloseRegEx = /{(?<tag>[^:}{]*):?\s*(?<text>[^}{]*)/
const tagMatchAllRegEx = /{(?<tag>[^:}{]*):?\s*(?<text>[^}{]*)}/g
const chordMatchRegEx = /\[/

const customTags = {
  tagColon: Tag.define(),
  tagName: Tag.define(),
  tagText: Tag.define(),
  chord: Tag.define()
}

export const highlights = HighlightStyle.define([
  { tag: tags.bracket, class: 'cp-style-bracket' },
  { tag: tags.squareBracket, class: 'cp-style-square-bracket' },
  { tag: customTags.tagColon, class: 'cp-style-tag-colon' },
  { tag: customTags.tagName, class: 'cp-style-tag-name' },
  { tag: customTags.tagText, class: 'cp-style-tag-text' },
  { tag: customTags.chord, class: 'cp-style-chord' }
])

let restrictedWords: string[] = [];
let invalidChords: string[] = [];

export function addRestrictedWords (words: string[]) {
  restrictedWords = words
}

export function addInvalidChords(chords: string[]){
  invalidChords = chords;
}

export const ChordProLint = (view: EditorView) => {
  let rule: ChordProRule | undefined
  const diagnostics: Diagnostic[] = []
  let allText = ''
  let foundRule = false
  for (let l = 1; l <= view.state.doc.lines; l++) {
    allText += view.state.doc.line(l).text + '\n'
  }

  // find all tags and iterate

  const singleUseTagsUsed = [] as {from: number, to: number, tag: string, multipleFound: boolean}[]
  const foundTagsInOrder = [] as string[];

  const tags = allText.matchAll(tagMatchAllRegEx)

  for (const tag of tags) {

    const from = tag.index
    const fullTag = tag[0];
    if (from === undefined) {
      continue
    }

    const to = from + fullTag.length
    const tagName = tag.groups?.tag
    const text = tag.groups?.text.trim()

    if (!tagName) {
      continue
    }
    const tagText = tag.groups?.text

    const foundDirective = Directives.find(d => d.tag.toLowerCase() === tagName.toLowerCase())
    if (foundDirective) {
      foundTagsInOrder.push(tagName);

      rule = filteredChordProRules.find(r => r.key === 'extraTextIsValidKey')
      if (rule && foundDirective.rules.extraTextIsValidKey && text){
        const testChord = text.replace(/m$/gi, '');
        if (!Chords.map( c=> c.toLowerCase()).includes(testChord.toLowerCase())){
          diagnostics.push({
            from,
            to,
            severity: rule.mode,
            message: rule.triggeredMessage
          })
        }
      }

      rule = filteredChordProRules.find(r => r.key === 'appearsAfterTag')
      if (rule && foundDirective.rules.appearsAfterTag){
        const found = foundTagsInOrder.find(tag => tag === foundDirective.rules.appearsAfterTag)

        if (!found){
          diagnostics.push({
            from,
            to,
            severity: rule.mode,
            message: rule.triggeredMessage
          })
        }
      }

      rule = filteredChordProRules.find(r => r.key === 'inputRequired')
      if (rule && foundDirective.rules.inputRequired && !tagText) {
        diagnostics.push({
          from,
          to,
          severity: rule.mode,
          message: rule.triggeredMessage + ` Example: ${foundDirective.example}`,
          source: rule.key
        })
      }

      rule = filteredChordProRules.find(r => r.key === 'noInputRequired')
      if (rule && !foundDirective.rules.inputRequired && tagText) {
        diagnostics.push({
          from,
          to,
          severity: rule.mode,
          message: rule.triggeredMessage,
          source: rule.key
        })
      }

      rule = filteredChordProRules.find(r => r.key === 'singleUseOnly')
      if (rule && foundDirective.rules.singleUseOnly) {
        const foundUsedTag = singleUseTagsUsed.find(u => u.tag === tagName)
        if (foundUsedTag) {
          foundUsedTag.multipleFound = true
          diagnostics.push({
            from,
            to,
            severity: rule.mode,
            message: rule.triggeredMessage,
            source: rule.key,
          })
        } else {
          singleUseTagsUsed.push({
            from,
            to,
            tag: tagName,
            multipleFound: false
          })
        }
      }

      rule = filteredChordProRules.find(r => r.key === 'overwrittenDuringEditing')
      if (rule && foundDirective.rules.overwrittenDuringEditing) {
        diagnostics.push({
          from,
          to,
          severity: rule.mode,
          message: rule.triggeredMessage,
          source: rule.key
        })
      }

      rule = filteredChordProRules.find(r => r.key === 'removedOnExport')
      if (rule && foundDirective.rules.removedOnExport) {
        diagnostics.push({
          from,
          to,
          severity: rule.mode,
          message: rule.triggeredMessage,
          source: rule.key
        })
      }

      rule = filteredChordProRules.find(r => r.key === 'existsOnOwnLine')
      if (rule && foundDirective.rules.existsOnOwnLine) {
        const checkLine = view.state.doc.lineAt(from)
        const lineStart = checkLine.from
        const lineEnd = checkLine.to
        let text = ''
        if (from !== lineStart) {
          text += allText.slice(lineStart, from)
        }
        if (to !== lineEnd) {
          text += allText.slice(to, lineEnd)
        }
        if (text.trim()) {
          diagnostics.push({
            from,
            to,
            severity: rule.mode,
            message: rule.triggeredMessage,
            source: rule.key
          })
        }
      }
    } else {
      rule = filteredChordProRules.find(r => r.key === 'noWhiteSpaceInTagName')
      if (rule && tagName.match(/\s+/)) {
        diagnostics.push({
          from: from + 1,
          to: from + 1 + tagName.length,
          severity: rule.mode,
          message: rule.triggeredMessage,
          source: rule.key
        })
        foundRule = true
      }

      if (!foundRule) {
        rule = filteredChordProRules.find(r => r.key === 'unsupportedTagByCCLI')
        if (rule) {
          diagnostics.push({
            from,
            to,
            severity: rule.mode,
            message: rule.triggeredMessage,
            source: rule.key
          })
        }
      }
    }

    rule = filteredChordProRules.find(r => r.key === 'tagCannotBeMultilined')
    if (rule && fullTag.split('\n').length > 1) {
      diagnostics.push({
        from,
        to,
        severity: rule.mode,
        message: rule.triggeredMessage,
        source: rule.key
      })
    }

    rule = filteredChordProRules.find(r => r.key === 'lowercaseTags')
    if (rule && tagName.toLowerCase() !== tagName) {
      diagnostics.push({
        from,
        to,
        severity: rule.mode,
        message: rule.triggeredMessage,
        source: rule.key
      })
    }
  }

  rule = filteredChordProRules.find(r => r.key === 'restrictedWords')
  if (rule && restrictedWords.length) {
    const ChordRegEx = /\[[^\]]*\]/gi;
    const DashesRegEx = /(\s*-\s*)/gi;

    let indexMap = [... allText].map((c,i) => ({c, i}));

    const updateIndexMap = (regex: RegExp) => {
      const text = indexMap.map(m => m.c).join('');
      const map = text.matchAll(regex);
      const remove: { start: number, end: number}[] = [];

      for (const found of map){
        if (found.index){
          const start = found.index as number;
          const end = start + found[0].length;
          remove.push({start, end})
        }
      }
      if (remove.length){
        indexMap = indexMap.filter((m,i) => !remove.find( r => i >= r.start && i < r.end));
      }
    }

    updateIndexMap(ChordRegEx)
    updateIndexMap(DashesRegEx)

    const filteredText = indexMap.map(m => m.c).join('');

    const words = restrictedWords.map( w => `\\b${w.replace(/^"/i, '').replace(/"$/i,'')}\\b`);

    const restrictedWordRegEx = new RegExp(words.join('|'), 'gi');

    const foundRestrictedWords = filteredText.matchAll(restrictedWordRegEx);

    for (const foundRestrictedWord of foundRestrictedWords) {
      const word = foundRestrictedWord[0]
      const wordFrom = foundRestrictedWord.index
      if (typeof(wordFrom) === 'number') {
        const wordTo = wordFrom + word.length

        diagnostics.push({
          from: indexMap[wordFrom].i,
          to: indexMap[wordTo].i,
          severity: rule.mode,
          message: rule.triggeredMessage,
          source: rule.key
        })
      }
    }
  }

  rule = filteredChordProRules.find(r => r.key === 'invalidChords')
  if (rule && invalidChords.length){

    for (const invalidChord of invalidChords){
      //providing literal values and not any escaped regex values
      const matches = allText.matchAll(new RegExp(invalidChord.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'));

      for (const match of matches){
        const text = match[0];

        if (match.index){
          diagnostics.push({
            from: match.index,
            to: match.index + text.length,
            severity: rule.mode,
            message: rule.triggeredMessage
          })
        }
      }
    }
  }


  rule = filteredChordProRules.find(r => r.key === 'missingBracketOnChord')
  if (rule) {
    const chords = allText.matchAll(/\[[^\]]*$/ig)
    for (const chord of chords) {
      const text = chord[0]
      if (text.split('\n').length > 1 && chord.index) {
        diagnostics.push({
          from: chord.index,
          to: chord.index + text.length - 1,
          severity: rule.mode,
          message: rule.triggeredMessage
        })
      }
    }
  }

  rule = filteredChordProRules.find(r => r.key === 'missingBracketOnTag')
  if (rule) {
    const tags = allText.matchAll(/{[^}]*$/ig)
    for (const tag of tags) {
      const text = tag[0]
      if (text.split('\n').length > 1 && tag.index) {
        diagnostics.push({
          from: tag.index,
          to: tag.index + text.length - 1,
          severity: rule.mode,
          message: rule.triggeredMessage
        })
      }
    }
  }

  // cover first instance of singleUseOnly tags that have been found multiple times
  rule = filteredChordProRules.find(r => r.key === 'singleUseOnly')
  const multipleTagInstances = singleUseTagsUsed.filter(u => u.multipleFound)
  if (rule) {
    diagnostics.push(...multipleTagInstances.map(t => ({
      from: t.from,
      to: t.to,
      severity: rule!.mode,
      message: rule!.triggeredMessage,
      source: rule!.key
    })))
  }

  return diagnostics
}

interface ParserState{
  type: null | string;
  keyword: string;
  additionalText: string;
  foundColon: boolean;
}

function resetState (state: ParserState) {
  state.type = null
  state.keyword = ''
  state.additionalText = ''
  state.foundColon = false
}

export const ChordProLang: StreamParser<ParserState> = {
  name: 'chordpro',
  startState: function () {
    return {
      type: null,
      keyword: '',
      additionalText: '',
      foundColon: false
    }
  },
  tokenTable: customTags,
  token: function (stream, state) {
    if (stream.eatSpace()) {
      return null
    }

    if (!state.type) {
      // check against directives
      let matches = stream.match(tagMatchNoCloseRegEx, false, true)
      if (matches) {
        const tag = (matches as RegExpMatchArray).groups?.tag ?? ''
        const text = (matches as RegExpMatchArray).groups?.text ?? ''

        state.type = 'tag'
        state.keyword = tag
        state.additionalText = text
        state.foundColon = false
      }

      // check against chords
      matches = stream.match(chordMatchRegEx, false, true)
      if (matches) {
        const chord = (matches as RegExpMatchArray).groups?.chord ?? ''
        state.type = 'chord'
        state.keyword = chord
        state.additionalText = ''
        state.foundColon = false
      }
    }

    if (state.type === 'tag') {
      if (stream.match('{')) {
        state.foundColon = false;
        return 'bracket'
      } else if (state.keyword && stream.match(state.keyword)) {
        return 'tagName'
      } else if (stream.match(':')) {
        state.foundColon = true
        return 'tagColon'
      } else if (state.additionalText && stream.match(state.additionalText)) {
        return 'tagText'
      } else if (stream.match('}')) {
        resetState(state)
        return 'bracket'
      }else {
        if (stream.eatWhile(/[^:}{]/)) {
          if (!state.foundColon) {
            return 'tagName'
          } else {
            return 'tagText'
          }
        }
      }
    }

    if (state.type === 'chord') {
      if (stream.match('[')) {
        return 'squareBracket'

      } else if (stream.match(']')) {
        resetState(state)
        return 'squareBracket'
      } else {
        if (stream.eatWhile(/[^\]]/)){
          return 'chord'
        }
      }
    }

    stream.next()
    return null
  }
}
